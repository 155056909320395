import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import 'hammerjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignDocumentComponent } from './pages/authenticated/sign-document/sign-document.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule } from '@angular/forms';
import { DashboardComponent } from './pages/authenticated/dashboard/dashboard.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthenticatedComponent } from './pages/authenticated/authenticated.component';
import { DocumentListComponent } from './pages/authenticated/document-list/document-list.component';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { DocumentComponent } from './pages/authenticated/document/document.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { SideNavigationComponent } from './components/side-navigation/side-navigation.component';
import { CalendarComponent } from './components/calendar/calendar.component';


@NgModule({
  declarations: [
    AppComponent,
    SignDocumentComponent,
    NavbarComponent,
    SignaturePadComponent,
    LoginComponent,
    DashboardComponent,
    AuthenticatedComponent,
    DocumentListComponent,
    DocumentComponent,
    BackButtonComponent,
    SideNavigationComponent,
    CalendarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgHeroiconsModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    FullCalendarModule,
    HammerModule
  ],
  providers: [
    importProvidersFrom(HttpClientModule),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
